<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">劳动关系</div>
      <div class="back" @click="goback">返回上一页</div>
    </div>
    <div class="from-box">
      <div class="line-box">
        <div class="line-title">职位序列</div>
        <el-select v-model="params.PositionHierarchyID" placeholder="请选择">
          <el-option
            v-for="item in PositionHierarchies"
            :key="item.ID"
            :label="item.PositionHierarchyName"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">用工形式</div>
        <el-select v-model="params.EmploymentFromID" placeholder="请选择">
          <el-option
            v-for="item in EmploymentForms"
            :key="item.ID"
            :label="item.EmploymentName"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">成本归集</div>
        <el-select v-model="params.CostAccumulationID" placeholder="请选择">
          <el-option
            v-for="item in CostAccumulations"
            :key="item.ID"
            :label="item.CostAccumulationName"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">兼职情况</div>
        <el-select v-model="params.PartTimeID" placeholder="请选择">
          <el-option
            v-for="item in PartTimes"
            :key="item.ID"
            :label="item.PartTimeName"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">用工协议签署单位</div>
        <el-select v-model="params.ContractUnitID" placeholder="请选择">
          <el-option
            v-for="item in ContractUnits"
            :key="item.ID"
            :label="item.ContractUnitCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">合同下载</div>
        <div class="bulue-title">劳动合同下载</div>
      </div>
      <div class="line-box line-upload">
        <div class="line-title">劳动合同</div>
        <div class="upload-load">
          <div class="left">
            <div class="name">
              <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
            </div>
          </div>
          <el-upload
            action="#"
            class="card-upload"
            :auto-upload="true"
            :http-request="uploadAttachment"
            ref="uploadRef"
          >
            <button class="btn-up disabled">
              <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
            </button>
          </el-upload>
        </div>
      </div>
      <div class="line-box">
        <div class="line-title">岗位性质</div>
        <el-select v-model="params.PostPropertiesID" placeholder="请选择">
          <el-option
            v-for="item in PostProperties"
            :key="item.ID"
            :label="item.PostPropertiesName"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">岗位名称</div>
        <el-select
          @change="changePositionsID"
          v-model="params.PositionsID"
          placeholder="请选择"
        >
          <el-option
            v-for="item in positionList"
            :key="item.ID"
            :label="item.PositionsCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">专业/系统</div>
        <el-select v-model="params.SpecialityId" disabled placeholder="请选择">
          <el-option
            v-for="item in PositionCategory"
            :key="item.ID"
            :label="item.NameCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>

      <div class="line-box">
        <div class="line-title">参加工作时间</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.JoinWorkTime"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">到本单位时间</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.JoinUnitTime"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">项目部信息</div>
        <el-select
          v-model="params.ProjectID"
          @change="changeProjectID"
          placeholder="请选择"
        >
          <el-option
            v-for="item in projectList"
            :key="item.ProjectID"
            :label="item.ProjectName"
            :value="item.ProjectID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">分项目部信息</div>
        <el-select v-model="params.SubProjectID" placeholder="请选择">
          <el-option
            v-for="item in subProjectList"
            :key="item.ProjectID"
            :label="item.ProjectName"
            :value="item.ProjectID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">用工单位信息</div>
        <el-select v-model="params.CompanyID" placeholder="请选择">
          <el-option
            v-for="item in companyList"
            :key="item.ID"
            :label="item.CompanyCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">部门信息</div>
        <el-select v-model="params.DepartmentID" placeholder="请选择">
          <el-option
            v-for="item in departmentList"
            :key="item.ID"
            :label="item.DepartmentCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">二级部门信息</div>
        <el-input
          v-model="params.SecondDepartmentName"
          placeholder="二级部门信息"
        />
      </div>
      <div class="line-box">
        <div class="line-title">任职情况</div>
        <el-select v-model="params.PositionNatureID" placeholder="请选择">
          <el-option
            v-for="item in PositionNatures"
            :key="item.ID"
            :label="item.PositionNatureName"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">职位名称</div>
        <el-input v-model="params.PositionName2022" placeholder="职位名称" />
      </div>
      <div class="buttons">
        <el-button class="pop-close" @click="goback">取消</el-button
        ><el-button class="pop-save" @click="addCompanies">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getLaborRelation,
  getLaborRelationBasic,
  getPositionCategory,
} from "@/api/other";
import {
  getProjectAll,
  getCompanyAll,
  allPosition,
  getDepartmentAll,
  getProjectsChildren,
} from "@/api/user";
export default {
  data() {
    return {
      value: null,
      params: {
        ID: null,
        PositionHierarchyID: null,
        EmploymentFromID: null,
        CostAccumulationID: null,
        ContractUnitID: null,
        ContracatURL: null,
        PostPropertiesID: null,
        PositionsID: null,
        SpecialityId: null,
        PostCategoryValue: null,
        PartTimeID: null,
        JoinWorkTime: null,
        JoinUnitTime: null,
        ProjectID: null,
        SubProjectID: null,
        CompanyID: null,
        DepartmentID: null,
        SecondDepartmentName: null,
        PositionNatureID: null,
        PositionName2022: null,
      },
      PositionHierarchies: [], //职位序列
      EmploymentForms: [], //用工形式
      CostAccumulations: [], //成本归集
      ContractUnits: [], //协议签署单位
      PostProperties: [], //岗位性质
      PartTimes: [], //兼职状况
      PositionNatures: [], //任职情况

      projectList: [],
      departmentList: [],
      positionList: [],
      companyList: [],
      PositionCategory: [],
      subProjectList: [],
    };
  },
  methods: {
    goback() {
      this.$router.push("/ordinary");
    },
    uploadAttachment() {},
    addCompanies() {},

    //信息
    getLaborRelation() {
      getLaborRelation().then((res) => {
        if (res.status === 200) {
          this.params = res.response;
        }
      });
    },
    //依赖
    getLaborRelationBasic() {
      getLaborRelationBasic().then((res) => {
        if (res.status === 200) {
          const data = res.response;
          this.PositionHierarchies = data.PositionHierarchies;
          this.EmploymentForms = data.EmploymentForms;
          this.CostAccumulations = data.CostAccumulations;
          this.ContractUnits = data.ContractUnits;
          this.PostProperties = data.PostProperties;
          this.PartTimes = data.PartTimes;
          this.PositionNatures = data.PositionNatures;
        }
      });
    },
    //岗位改变
    changePositionsID() {
      const item = this.positionList.find((item) => {
        return item.ID === this.params.PositionsID;
      });
      this.params.SpecialityId = item.SpecialityId;
      console.log("item", item);
    },
    //项目部改变
    changeProjectID() {
      this.getProjectsChildren();
      this.params.SubProjectID = null;
    },
    getProjectsChildren() {
      getProjectsChildren({ id: this.params.ProjectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },
  },

  created() {
    this.getLaborRelation();
    this.getLaborRelationBasic();
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getPositionCategory().then((res) => {
      if (res.status === 200) {
        this.PositionCategory = res.response;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .back {
      margin-left: 24px;
      color: #257eff;
      cursor: pointer;
    }
  }
  .from-box {
    width: 100%;
    min-height: calc(100% - 40px);
    background-color: #fff;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    padding-top: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    align-content: flex-start;
    .line-box {
      width: 30%;
      margin-bottom: 24px;
      .line-title {
        font-size: 14px;
        color: #100303;
        margin-bottom: 10px;
      }
      .el-select {
        width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .bulue-title {
        color: #257eff;
        cursor: pointer;
      }
    }
    .line-box2 {
      width: 24%;
    }
    .line-upload {
      width: 100%;
    }
    .upload-load {
      display: flex;
      height: 34px;
      //   margin-top: 24px;
      .left {
        width: 100%;
        border: 1px solid #d2d6de;
        border-right: none;
        .name {
          display: flex;
          align-items: center;
          height: 34px;
          color: #555;
          font-size: 14px;
          text-indent: 2px;
        }
      }
      .btn-up {
        padding: 0;
        margin: 0;
        border: none;
        width: 90px;
        flex-shrink: 0;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #3c8dbc;
        border: 1px solid #367fa9;
        color: #fff;
        font-size: 14px;
        .icon {
          margin-right: 5px;
        }
      }
      .disabled {
        cursor: no-drop;
      }
      .btn-up:hover {
        border-color: #204d74;
        background-color: #367fa9;
      }
    }
    .buttons {
      width: 100%;

      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
